export const GET_ITEMS = 'GET_ITEMS';
export const ADD_ITEM = 'ADD_ITEMS';
export const DELETE_ITEM = 'DELETE_ITEMS';
export const ITEMS_LOADING = 'ITEMS_LOADING';
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCES = 'LOGIN_SUCCES';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCES = 'LOGOUT_SUCCES';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';
export const REGISTER_SUCCES = 'REGISTER_SUCCES';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const GET_WALLET = 'GET_WALLET';
export const WALLET_LOADING = 'WALLET_LOADING';
export const ADD_WALLET = 'ADD_WALLET';
export const DELETE_WALLET = 'DELETE_WALLET';
export const SET_WALLET_STATS = 'SET_WALLET_STATS';
export const SET_WALLET_PRICE_DATA = 'SET_WALLET_PRICE_DATA';

export const GET_WALLET_TOP_LIST = 'GET_WALLET_TOP_LIST';
export const TOP_LIST_LOADING = 'TOP_LIST_LOADING';

export const GET_ADDRESSES = 'GET_ADDRESSES';
export const ADDRESSES_LOADING = 'ADDRESSES_LOADING';

