import React, {Component} from "react";
import { Button, Modal, ModalHeader,ModalBody, Form, FormGroup, Label, Input } from "reactstrap";
import { connect } from "react-redux";
import {addItem} from '../actions/itemActions'
import PropTypes from 'prop-types';


class ItemModal extends Component {
    state={
        modal: false,
        name: ''  
    }

static propTypes={
    isAuthenticated: PropTypes.bool
}

    toggle =()=>{
        this.setState({
            modal: !this.state.modal
        });
    }

    onChange=(e)=>
    this.setState({[e.target.name]:e.target.value});

    onSubmit=e=>{
        e.preventDefault();
        console.log('name in item modal' +this.state.name);
        const newItem={
            name: this.state.name
        }
        //Adding via addItem action
        console.log('adding in item modal');
        this.props.addItem(newItem);

        //Close modal
        this.toggle();
    }

  render() {
    return <div>
        {this.props.isAuthenticated?
        <Button
        color="dark"
        style={{marginBottom: '2rem'}}
        onClick={this.toggle}
        >Add item</Button>: <h4 className="mb-4 ml-4">Please log in to manage list</h4>}

        <Modal
        isOpen={this.state.modal}
        toggle={this.toggle}        
        >
            <ModalHeader toggle={this.toggle}>
                Add toShoping List
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={this.onSubmit}>
                    <FormGroup>
                        <Label for="item">Item</Label>
                        <Input
                        type="text"
                        name="name"
                        id="item"
                        placeholder="Add shoping item"
                        onChange={this.onChange}
                        />
                        <Button
                        color="dark"
                        style={{marginTop:'2rem'}}
                        block
                        >ADD ITEM</Button>
                    </FormGroup>
                </Form>
            </ModalBody>
        </Modal>
    </div>;
  }
}
 

const mapStateToProps = state => ({
    item:state.item,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, {addItem})(ItemModal);